<template>
  <b-card>
    <div class="my-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
            cols="12"
            sm="6"
            class="mb-1 mb-md-0"
        >
          <div class="d-inline-flex align-items-center gap-1">
            <v-select
                v-model="perPage"
                :options="[10, 25, 50, 100, 'Tất cả']"
                :clearable="false"
                class="per-page-selector select-size-sm"
            />
            <slot name="button_event"></slot>
          </div>
        </b-col>
        <!-- Search -->
        <b-col
            cols="12"
            sm="6"
        >
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <i class="fas fa-search"/>
            </b-input-group-prepend>
            <b-input id="table_search_field"
                     v-model="searchQuery"
                     :placeholder="$t('table.label.search')"
                     debounce="800"
                     @update="textSearchChange"
            />
            <b-input-group-append>
              <b-button variant="primary"
                        :disabled="!searchQuery"
                        @click="searchQuery = ''"
                        size="sm"
              >
                {{ $t('table.button.clear') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="$scopedSlots['on_top_table']" class="mb-1">
      <b-row>
        <b-col cols="12">
          <slot name="on_top_table"/>
        </b-col>
      </b-row>
    </div>
    <b-table
        ref="selectableTable"
        :items="tableData"
        :fields="tableColumns"
        primary-key="id"
        stacked="sm"
        responsive
        striped
        small
        hover
        show-empty
        :empty-text="$t('table.label.no_data')"
        :empty-filtered-text="$t('table.label.search_no_data')"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        :busy.sync="isDataLoading"
        @filtered="onFiltered"
        :tbody-tr-class="tbodyRowClass"
        @row-clicked="onRowClicked"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-50"></b-spinner>
          <strong>{{ $t('table.label.loading') }}</strong>
        </div>
      </template>

      <template v-for="(field, index) in tableColumns" #[`cell(${field.key})`]="data">
        <slot v-if="$scopedSlots[`cell(${field['key']})`]" :name="`cell(${field['key']})`" v-bind:data="data"/>
        <div v-else-if="data.value === ''">-</div>
        <div v-else-if="data.value === 0">0</div>
        <div v-else v-html="data.value"/>
      </template>

      <template #head(is_selected)="">
        <b-form-checkbox
            v-model="checkedHeader['isSelectedAllRow']"
            :indeterminate.sync="checkedHeader['isIndeterminate']"
            @change="selectedAllItemsOnchange"
        >
        </b-form-checkbox>
      </template>
      <template #cell(is_selected)="{item}">
        <b-form-checkbox
            v-model="item['is_selected']"
        >
        </b-form-checkbox>
      </template>

      <template #cell(user)="data">
        <a v-if="![null, undefined].includes(data.item['user']['user_name'])"
           :href="data.item['user']['facebook_url']" target="_blank"
           :title="data.item['user']['full_name']"
           v-b-tooltip.hover.v-primary
        >
          <strong>{{ data.item['user']['user_name'] }}</strong>
        </a>
        <span v-else>-</span>
      </template>
      <template #cell(created_at)="data">
        <span>{{ convertToISODate(data.item['created']['at']) }}</span>
      </template>
      <template #cell(created_by)="data">
        <a v-if="![null, undefined].includes(data.item['created']['by']['user_name'])"
           :href="data.item['created']['by']['facebook_url']" target="_blank"
           :title="data.item['created']['by']['full_name']"
           v-b-tooltip.hover.v-primary
        >
          <strong>{{ data.item['created']['by']['user_name'] }}</strong>
        </a>
        <span v-else>-</span>
      </template>
      <template #cell(updated_at)="data">
        <span>{{ convertToISODate(data.item['updated']['at']) }}</span>
      </template>
      <template #cell(start_date)="data">
        <span>{{ convertToISODate(data.item['start_date']) }}</span>
      </template>
      <template #cell(end_date)="data">
        <span>{{ convertToISODate(data.item['end_date']) }}</span>
      </template>

      <template #cell(region)="data">
        <span class="cursor-pointer" v-b-tooltip.hover.v-info="`${data.item.region['country']} (${data.item.region['state']})`">
          <strong>{{ data.item.region['country_code'] }}</strong>
          <span class="font-small-1"> ({{ data.item.region['state_code'] }})</span>
        </span>
      </template>
      <template #cell(is_active)="data">
        <b-badge v-if="data.value" variant="light-success">
          Đang hoạt động
        </b-badge>
        <b-badge v-else variant="light-secondary">
          Không hoạt động
        </b-badge>
      </template>
      <template #cell(phone_number)="data">
        <span>{{ phoneNumberFormatter(data.value) }}</span>
      </template>
      <template #cell(payment_status)="data">
        <b-badge :variant="`light-${verifyCloudVpsPaymentStatus(data.item)['variant']}`">
          <div class="d-flex">
            <i class="mr-50" :class="verifyCloudVpsPaymentStatus(data.item)['icon']"/>
            <span class="align-self-center">{{ $t(`cloudVps.table.content.payment_status.${verifyCloudVpsPaymentStatus(data.item)['status']}`) }}</span>
          </div>
        </b-badge>
      </template>
      <template #cell(operation_status)="data">
        <b-badge
            href="javascript:;"
            class="d-flex align-items-center"
            :variant="`${verifyCloudVpsStatus(data.item['operation_status'])['variant']}`"
            size="sm"
            :disabled="verifyCloudVpsStatus(data.item['operation_status'])['icon'] === 'LoaderIcon'"
            @click="$emit('checkOperationStatus', data.item)"
        >
          <b-spinner
              v-if="verifyCloudVpsStatus(data.item['operation_status'])['icon'] === 'LoaderIcon'"
              class="mr-50"
              small
          />
          <feather-icon
              v-else
              :icon="verifyCloudVpsStatus(data.item['operation_status'])['icon']"
              class="mr-50"
          />
          <span>{{ $t(`cloudVps.table.content.operation_status.${data.item['operation_status']}`) }}</span>
        </b-badge>
      </template>

      <template #cell(menu)="data">
        <div>
          <b-dropdown
              class="menu-badge full-width"
              variant="primary"
              toggle-class="p-0"
              right
              no-caret
              boundary="viewport"
          >
            <template #button-content>
              <b-badge
                  href="javascript:;"
                  class="d-flex align-items-center justify-content-between cursor-pointer"
                  variant="primary"
                  size="sm"
                  @click="$emit('menuClick', data.item)"
              >
              <span>
                <feather-icon
                    icon="MenuIcon"
                    class="mr-50"
                />
              <span>Menu</span>
              </span>
                <feather-icon
                    icon="ChevronDownIcon"
                    class="ml-1"
                />
              </b-badge>
            </template>
            <slot name="menu" :item="data.item"/>
          </b-dropdown>
        </div>
      </template>
      <template #row-details="data">
        <slot name="row-details" v-bind:data="data"/>
      </template>
    </b-table>
    <div class="my-2">
      <b-row>

        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span v-if="selectedRows.length === 0" class="text-muted">Hiển thị từ mục {{ dataMeta.from }} tới mục {{ dataMeta.to }} trong tổng số {{ dataMeta.of }} mục.</span>
          <span v-else class="text-muted">Có <strong class="text-danger">{{ selectedRows.length }}</strong> mục được chọn.</span>
        </b-col>
        <!-- Pagination -->
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
              v-model="currentPage"
              :total-rows="paginationValue['totalRows']"
              :per-page="paginationValue['perPage']"
              hide-goto-end-buttons
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>

// import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import inputHelper from '@/mixins/utils/inputHelper'

export default {
  name: 'BTableCustom',
  components: {
    vSelect
  },
  props: {
    tableData: Array,
    tableColumns: {
      default: () => []
    },
    isDataLoading: Boolean
  },
  mixins: [inputHelper],
  data () {
    return {
      perPage: 25,
      currentPage: 1,
      searchQuery: '',
      dataRowLength: 0,
      filterItems: []
    }
  },
  watch: {
    tableData: {
      handler () {
        this.dataRowLength = this.tableData.length
        this.$refs.selectableTable.refresh()
        this.$emit('selected-item-onchange', this.selectedRows)
      },
      deep: true
    }
  },
  computed: {
    dataMeta () {
      let perItemsOnPage = this.perPage === 'Tất cả' ? this.dataRowLength : this.perPage

      return {
        from: this.dataRowLength < 1 ? 0 : (perItemsOnPage * (this.currentPage - 1)) + 1,
        to: perItemsOnPage * this.currentPage > this.dataRowLength ? this.dataRowLength : perItemsOnPage * this.currentPage,
        of: this.dataRowLength
      }
    },
    paginationValue () {
      if (this.perPage === 'Tất cả') {
        return {
          totalRows: parseInt(this.tableData.length) || 0,
          perPage: parseInt(this.tableData.length) || 0
        }
      } else {
        return {
          totalRows: this.dataRowLength || 0,
          perPage: this.perPage || 0
        }
      }
    },
    selectedRows () {
      return this.tableData.filter(item => item['is_selected'])
    },
    checkedHeader () {
      const itemSelectedOnPage = this.currentPageItems.filter(item => item['is_selected'])
      if (this.currentPageItems.length === 0) {
        return {
          isSelectedAllRow: false,
          isIndeterminate: false
        }
      } else if (itemSelectedOnPage.length === this.currentPageItems.length) {
        return {
          isSelectedAllRow: true,
          isIndeterminate: false
        }
      } else if (itemSelectedOnPage.length === 0) {
        return {
          isSelectedAllRow: false,
          isIndeterminate: false
        }
      } else {
        return {
          isSelectedAllRow: false,
          isIndeterminate: true
        }
      }
    },
    currentPageItems () {
      if (this.filterItems.length > 0) {
        return this.filterItems.slice(this.dataMeta['from'] - 1, this.dataMeta['to'])
      } else {
        return this.tableData.slice(this.dataMeta['from'] - 1, this.dataMeta['to'])
      }
    }
  },
  mounted () {
    this.tableColumns.splice(0, 0, {
      key: 'is_selected'
    })
  },
  created () {
    this.tableColumns.forEach(column => {
      column.class = 'font-small-2'
      column.sortable = true
    })
  },
  methods: {
    onFiltered (filteredItems) {
      this.filterItems = filteredItems
      this.dataRowLength = filteredItems.length
    },
    onRowClicked (item) {
      this.$set(item, 'is_selected', !item['is_selected'])
    },
    selectedAllItemsOnchange () {
      if (this.checkedHeader['isSelectedAllRow']) {
        this.currentPageItems.forEach((item) => {
          this.$set(item, 'is_selected', true)
        })
      } else {
        this.currentPageItems.forEach((item) => {
          this.$set(item, 'is_selected', false)
        })
      }
    },
    textSearchChange () {
      this.currentPage = 1
    },
    tbodyRowClass (item) {
      if (item !== null && item['is_selected'] === true) {
        return ['cursor-default', 'b-table-row-selected', 'table-active']
      } else {
        return ['cursor-default']
      }
    },
    getSelectedData () {
      return this.selectedRows
    },

    verifyCloudVpsStatus (cloudVpsStatus) {
      switch (cloudVpsStatus) {
        case 'Press':
          return {variant: 'primary', icon: 'RefreshCwIcon'}
        case 'Running':
          return {variant: 'success', icon: 'CheckCircleIcon'}
        case 'Success':
          return {variant: 'success', icon: 'CheckCircleIcon'}
        case 'Stopped':
          return {variant: 'warning', icon: 'StopCircleIcon'}
        case 'Error':
          return {variant: 'danger', icon: 'AlertCircleIcon'}
        case 'Suspended':
          return {variant: 'danger', icon: 'AlertCircleIcon'}
        case 'Unknown':
        case undefined:
          return {variant: 'secondary', icon: 'HelpCircleIcon'}
        default:
          return {variant: 'info', icon: 'LoaderIcon'}
      }
    },
    verifyCloudVpsPaymentStatus (rowData) {
      if (rowData['is_suspended']) {
        return {variant: 'danger', status: 'suspend', icon: 'fas fa-pause'}
      } else if (rowData['payment_status'] === 'ok') {
        if (rowData['end_date'] <= this.getCurrentTime()) {
          return {variant: 'warning', status: 'overdue', icon: 'fas fa-exclamation-triangle'}
        } else {
          return {variant: 'success', status: 'ok', icon: 'fas fa-check-circle'}
        }
      } else if (rowData['payment_status'] === 'suspend') {
        return {variant: 'danger', status: 'suspend', icon: 'fas fa-pause'}
      } else if (rowData['payment_status'] === 'cancel') {
        return {variant: 'secondary', status: 'cancel', icon: 'fas fa-ban'}
      } else {
        return {variant: 'info', status: 'unknown', icon: 'fas fa-question-circle'}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 120px;
}
.column-note {
  min-width: 150px;
}
.table-custom {
  min-width: 1200px;
}
</style>

<style lang="css" scoped>

table tbody tr {
  cursor: default !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
